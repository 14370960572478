<template>
	<div class="p-grid crud-demo">
		<div class="p-col-12">
			<div class="card">
				<Toast/>
				<Toolbar class="p-mb-4">
					<template v-slot:left>
						<router-link to="/editProduct"><Button label="Добавить" icon="pi pi-plus" class="p-button-success p-mr-2" @click="openNew"/></router-link>
						<router-link to="/productsCategories"><Button label="Категории" icon="pi pi-folder" class="p-button-info p-mr-2" /></router-link>
						<router-view></router-view>
						<!-- <Button label="Удалить" icon="pi pi-trash" class="p-button-danger" @click="confirmDeleteSelected" :disabled="!selectedProducts || !selectedProducts.length" /> -->
					</template>

					<template v-slot:right>
						<FileUpload mode="basic" accept="image/*" :maxFileSize="1000000" label="Import" chooseLabel="Import" class="p-mr-2 p-d-inline-block" />
						<Button label="Export" icon="pi pi-upload" class="p-button-help" @click="exportCSV($event)"  />
					</template>
				</Toolbar>

				<DataTable ref="dt" :value="products" v-model:expandedRows="expandedRows" dataKey="id" :paginator="true" :rows="10" :filters="filters"
							paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25]"
							currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products" responsiveLayout="scroll"
							@row-expand="onRowExpand" @row-collapse="onRowCollapse">
					<template #header>
						<div class="table-header p-d-flex p-flex-column p-flex-md-row p-jc-md-between">
							<h5 class="p-m-0">Наши продукты и услуги:</h5>
							<span class="p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global']" placeholder="Search..." />
                            </span>
						</div>
					</template>
					<Column :expander="true" headerStyle="width: 3rem"></Column>

					<Column header="Картинка">
						<template #body="slotProps">
							<span class="p-column-title">Картинки</span>
							<img :prod-firebase-id="slotProps.data.id" :src="slotProps.data.image_url"  class="product-image" />
						</template>
					</Column> 

					
					<Column field="code" header="Артикул" :sortable="true">
						<template #body="slotProps">
							<span class="p-column-title">Артикул</span>
							{{slotProps.data.code}}
						</template>
					</Column>
					<Column field="name" header="Название" :sortable="true">
						<template #body="slotProps">
							<span class="p-column-title">Название</span>
							{{slotProps.data.name}}
						</template>
					</Column>
					
					<Column field="inventoryStatus" header="Кол-во" :sortable="true">
						<template #body="slotProps">
							<span class="p-column-title">Кол-во</span>
							{{slotProps.data.quantity}}
							<!-- <span :class="'product-badge status-' + (slotProps.data.inventoryStatus ? slotProps.data.inventoryStatus.toLowerCase() : '')">{{slotProps.data.inventoryStatus}}</span>-->
						</template>
					</Column>
					<Column field="price" header="Цена" :sortable="true">
						<template #body="slotProps">
							<span class="p-column-title">Цена</span>
							{{formatCurrency(slotProps.data.price)}}
						</template>
					</Column>
					<Column field="factory" header="Производитель" :sortable="true">
						<template #body="slotProps">
							<span class="p-column-title">Производитель</span>
							{{formatCurrency(slotProps.data.factory)}}
						</template>
					</Column>
					
					<Column>
						<template #body="slotProps">							
							<Button icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2" @click="editProduct(slotProps.data.id)" />
							
							<Button icon="pi pi-trash" class="p-button-rounded p-button-warning" @click="confirmDeleteProduct(slotProps.data)" />
						</template>
					</Column>
					<template #expansion="slotProps">
						<div class="orders-subtable">
							<h5>Быстрое редактирование продукта: {{slotProps.data.name}}</h5>
							<TabView>
								<TabPanel header="Общая информация">
									Тут поля с формы
								</TabPanel>
								<TabPanel header="Картинки">
									тут будет компонента с аплоудом картинок универсальная
								</TabPanel>
								<TabPanel header="Категории">

								</TabPanel>
								<TabPanel header="SEO">
									поля seoUrl, seoKeywords, seoDescription, publish (bool)
								</TabPanel>
							</TabView>
						</div>
					</template>

				</DataTable>

				<Dialog v-model:visible="deleteProductDialog" :style="{width: '450px'}" header="Confirm" :modal="true">
					<div class="confirmation-content">
						<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
						<span v-if="product">Are you sure you want to delete <b>{{product.name}}</b>?</span>
					</div>
					<template #footer>
						<Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteProductDialog = false"/>
						<Button label="Yes" icon="pi pi-check" class="p-button-text" @click="deleteProduct" />
					</template>
				</Dialog>

				<Dialog v-model:visible="deleteProductsDialog" :style="{width: '450px'}" header="Confirm" :modal="true">
					<div class="confirmation-content">
						<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
						<span v-if="product">Are you sure you want to delete the selected products?</span>
					</div>
					<template #footer>
						<Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteProductsDialog = false"/>
						<Button label="Yes" icon="pi pi-check" class="p-button-text" @click="deleteSelectedProducts" />
					</template>
				</Dialog>
			</div>
		</div>
	</div>

</template>

<script>
import ProductService from '../service/ProductService';
import ImageService from "../service/ImageService";
import firebase from "firebase";


export default {
	data() {
		return {
			products: null,
			productDialog: false,
			deleteProductDialog: false,
			deleteProductsDialog: false,
			product: {},
			expandedRows: [],
			selectedProducts: null,
			filters: {},
			submitted: false,
			statuses: [
				{label: 'INSTOCK', value: 'instock'},
				{label: 'LOWSTOCK', value: 'lowstock'},
				{label: 'OUTOFSTOCK', value: 'outofstock'}
			],
			lastSelectedImage: null,
			persentRating: null,
		}
	},
	productService: null,
	imageService: null,
	created() {
		this.productService = new ProductService();
		this.imageService = new ImageService();
	},
	mounted() {
		this.productService.getProducts().then(data => this.products = data);
	},
	methods: {
			onRowExpand(event) {
				this.$toast.add({severity: 'info', summary: 'Product Expanded', detail: event.data.name, life: 3000});
			},
			onRowCollapse(event) {
				this.$toast.add({severity: 'success', summary: 'Product Collapsed', detail: event.data.name, life: 3000});
			},
			expandAll() {
				this.expandedRows = this.products.filter(p => p.id);
				this.$toast.add({severity: 'success', summary: 'All Rows Expanded', life: 3000});
			},
			collapseAll() {
				this.expandedRows = null;
				this.$toast.add({severity: 'success', summary: 'All Rows Collapsed', life: 3000});
			},
		formatCurrency(value) {
			if(value)
				return value.toLocaleString('en-US', {style: 'currency', currency: 'UAH'});
			return;
		},
		openNew() {
			this.product = {};
			this.submitted = false;
			//this.productDialog = true;
		},
		// hideDialog() {
		// 	this.productDialog = false;
		// 	this.submitted = false;
		// },
		async set_UrlImage(prod) {
			const imageRef = firebase.storage().ref().child('images/' + prod.image);
			const url = await imageRef.getDownloadURL()
			document.querySelectorAll(`[prod-firebase-id="${prod.id}"]`).forEach(el => el.src = url )
		},

		editProduct(prod) {
			//this.product = {...product};
			console.log(prod);
			//this.productDialog = true;
			this.$router.push({ name: 'editProduct', params: { id: prod } })
		},
		confirmDeleteProduct(product) {
			this.product = product;
			this.deleteProductDialog = true;
		},

		async deleteProduct() {
			this.products = this.products.filter(val => val.id !== this.product.id);
			await this.productService.deleteProduct(this.product.id);
			await this.imageService.deleteImages(this.product.id);
			this.deleteProductDialog = false;
			this.product = {};
			this.$toast.add({severity:'success', summary: 'Successful', detail: 'Product Deleted', life: 3000});
		},
		findIndexById(id) {
			let index = -1;
			for (let i = 0; i < this.products.length; i++) {
				if (this.products[i].id === id) {
					index = i;
					break;
				}
			}
			return index;
		},
		createId() {
			let id = '';
			var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
			for ( var i = 0; i < 5; i++ ) {
				id += chars.charAt(Math.floor(Math.random() * chars.length));
			}
			return id;
		},
		exportCSV() {
			this.$refs.dt.exportCSV();
		},
		confirmDeleteSelected() {
			this.deleteProductsDialog = true;
		},
		deleteSelectedProducts() {
			this.products = this.products.filter(val => !this.selectedProducts.includes(val));
			this.deleteProductsDialog = false;
			this.selectedProducts = null;
			this.$toast.add({severity:'success', summary: 'Successful', detail: 'Products Deleted', life: 3000});
		}
	},
	components: {
		// 'image-from-firebase': ImageFromFirebase
	}
}
</script>

<style scoped lang="scss">
	.table-header {
		display: flex;
		justify-content: space-between;
	}

	.product-image {
		width: 100px;
		box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
	}

	.p-dialog .product-image {
		width: 150px;
		margin: 0 auto 2rem auto;
		display: block;
	}

	.confirmation-content {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.product-badge {
		border-radius: 2px;
		padding: .25em .5rem;
		text-transform: uppercase;
		font-weight: 700;
		font-size: 12px;
		letter-spacing: .3px;

		&.status-instock {
			background: #C8E6C9;
			color: #256029;
		}

		&.status-outofstock {
			background: #FFCDD2;
			color: #C63737;
		}

		&.status-lowstock {
			background: #FEEDAF;
			color: #8A5340;
		}
	}

	::v-deep(.p-toolbar) {
		flex-wrap: wrap;
		.p-button {
			margin-bottom: .25rem;
		}
	}
	
</style>
